import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VAppBar,{attrs:{"app":""}},[_c('img',{staticClass:"mr-3",attrs:{"src":require('../assets/logo.png'),"height":"50","alt":"Wg Gen Web"}}),_c(VToolbarTitle,{attrs:{"to":"/"}},[_vm._v("Artificial WireGuard VPN")]),_c(VSpacer),_c(VToolbarItems,[_c(VBtn,{attrs:{"to":"/clients"}},[_vm._v(" Clients "),_c(VIcon,{attrs:{"right":"","dark":""}},[_vm._v("mdi-account-network-outline")])],1),_c(VBtn,{attrs:{"to":"/server"}},[_vm._v(" Server "),_c(VIcon,{attrs:{"right":"","dark":""}},[_vm._v("mdi-vpn")])],1),_c(VBtn,{attrs:{"to":"/status"}},[_vm._v(" Status "),_c(VIcon,{attrs:{"right":"","dark":""}},[_vm._v("mdi-chart-bar")])],1)],1),_c(VMenu,{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-account-circle")])],1)]}}])},[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"344","outlined":""}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemContent,[_c('div',{staticClass:"overline mb-4"},[_vm._v("connected as")]),_c(VListItemTitle,{staticClass:"headline mb-1"},[_vm._v(_vm._s(_vm.user.name))]),_c(VListItemSubtitle,[_vm._v("Email: "+_vm._s(_vm.user.email))]),_c(VListItemSubtitle,[_vm._v("Issuer: "+_vm._s(_vm.user.issuer))]),_c(VListItemSubtitle,[_vm._v("Issued at: "+_vm._s(_vm._f("formatDate")(_vm.user.issuedAt)))])],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"small":""},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" logout "),_c(VIcon,{attrs:{"small":"","right":"","dark":""}},[_vm._v("mdi-logout")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }